<template>
    <div class="container">
        <!-- produk filter -->
        <div class="shadow-sm p-3 m-2 mb-3 rounded">
            <p class="fw-bold h4 p-3">Daftar Barang</p>
            <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-refresh="showRefresh"
            :click-delete="showDelete"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
        />
        </div>

        <!-- daftar produk -->
        <div class="shadow-sm p-3 m-2 rounded">
            <div class="container-fluid p-0">
                <div id="wrapper">
                    <div id="content">
                        <div class="w3-hide-small w3-hide-medium" id="boundary">
                            <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-strip">
                                <thead>
                                    <tr class="">
                                        <th width="50px" class="h9">No. </th>
                                        <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9">{{fl.name}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-360) +'px;'">
                            <table class="table table-strip">
                                <tbody>
                                <tr v-for="(dt, index) in recs" :key="dt.tp_id" class=" w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                    <td width="50px" class="text-center p-3 align-middle">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" class="w3-hide-small w3-hide-medium p-3">
                                        <div class="d-flex flex-row">
                                            <div class="">
                                                <img :src="dt.ti_id" style="width: 65px;" class="rounded">
                                            </div>
                                            <div class="ms-3 mt-2">
                                                <p class="fw-bold h5 text-uppercase">{{dt.tp_name}}</p>
                                                <p class="text-black-50 h9 text-uppercase">{{dt.tb_name}} - {{dt.tk_name}}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- pagging -->
            <nav class="Page navigation h9">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>
        <!-- modal css -->
        <div id="modal-form" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md" style="max-height: 650px;">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-form').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Tambah / Edit Data</p>
                    </div>
                    <div class="modal-body">
                        <form class="container" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                            <label for="">Produk ID</label>
                                <input readonly type="text" class="form-control mb-3" id="tp_id" v-model="rec.tp_id">
                            <label for="">Nama Produk</label>
                                <input type="text" class="form-control mb-3" id="tp_name" placeholder="Nama Produk" v-model="rec.tp_name">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="" class="">Brand</label>
                                <model-list-select
                                    :list="brands"
                                    class="form-select mb-3"
                                    option-value="tb_id"
                                    option-text="tb_name"
                                    v-model="rec.tb_id"
                                    placeholder="Brand">
                                </model-list-select>
                                </div>
                                <div class="col-md-4">
                                    <label for="" class="">Kategori</label>
                                    <model-list-select
                                        :list="kategori"
                                        class="form-select mb-3"
                                        option-value="tk_id"
                                        option-text="tk_name"
                                        v-model="rec.tk_id"
                                        placeholder="Kategori">
                                    </model-list-select>
                                </div>
                                <div class="col-md-4">
                                    <label for="">Dimensi</label>
                                    <input type="text" class="form-control" id="tp_dimen" placeholder="Dimensi" v-model="rec.tp_dim">
                                </div>
                            </div>
                            <label for="">Deskripsi</label>
                                <textarea type="text" class="form-control mb-3" id="tp_desc" placeholder="Deskripsi Produk" v-model="rec.tp_desc"></textarea>
                            <label for="">Alamat Tokopedia</label>
                                <input type="text" class="form-control mb-3" id="tp_tokped" placeholder="Link tokopedia" v-model="rec.tp_tokped">
                                <label for="tp_wa" class="form-label">Nomer sales</label>
                                    <input type="text" class="form-control mb-3" id="tp_wa" placeholder="62.." v-model="rec.tp_wa">

                                    <div class="mb-3">
                                        <label for="fileInput" class="form-label mb-3">Alamat Gambar</label>
                                            <div class="input-group">
                                                <input type="file" ref="fileInput" multiple accept="image/*" @change="handleFileChange" class="form-control">
                                                <button type="button" class="btn btn-primary" @click="$refs.fileInput.click()">Pilih Gambar</button>
                                            </div>
                                            <figure>
                                            <figcaption class="blockquote-footer">
                                                Someone famous in <cite title="Source Title">Source Title</cite>
                                            </figcaption>
                                            </figure>

                                        <div class="my-3">
                                        <div class="row row-cols-2 row-cols-md-3 g-3">
                                            <div v-for="(file, index) in selectedFiles" :key="index" class="col mb-3">
                                                <div class="card">
                                                <img :src="file.preview" class="card-img-top rounded">
                                                <div class="card-body">
                                                    <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">Hapus</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="status" v-model="rec.status" :checked="rec.status == 1">
                                            <label class="form-check-label" id="status">Produk Aktif </label>
                                          </div>
                                      </div>
                            </form>

                        <footer class="modal-footer mt-4 pb-0">
                            <button form="frmGdg" class="btn btn-success" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </footer>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";
import Toolbar from "@/components/olshopTools.vue";
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("@/assets/swal/theme.css");
// import $ from "jquery";

export default {

// head: {
//     title: {
//       inner: 'Main'
//     }
// },
name : 'Produk',
components: {
    Toolbar,
    ModelListSelect
},
data() {
    return {
        username : localStorage.username,
        userz : [],
        screenHeight : 0,
        selectedRow : -1,
        DatePickerFormat: 'YYYY-MM-DD',
        rec: {},
        recs: [],
        recz: {},
        reci: [],
        brands: [],
        kategori: [],
        selectedFiles: [],
        pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
        filters : {
            operator : 'AND',
            fields :[
                    {fieldFind : '', key : 'tp_name', type: 'text', name : 'Detail Produk', filter : true, width : 'auto', grid : true},

            ],
            find : []
        },
    }
},
methods : {
    findObjFind(key, val){
        return this.findObj(this.filters.fields,'key',val)[key];
    },
    findField(val){
        return this.findObj(this.fieldRules, 'field',val);
    },
    findObj(obj, key, value) {
        let ketemu = 0;
        for (var pkey in obj) {
            if (obj[pkey][key] == value) {
                break;
            } else {
                ketemu++;
            }
        }
        if(obj[ketemu]!=undefined){
            return obj[ketemu];
        }else
            return null;
    },
    first(){
        let self = this;
        self.pages.halaman = 1;
        self.loadData();
    },
    prev(){
        let self = this;
        self.pages.halaman = self.pages.halaman - 1;
        self.loadData();
    },
    next(){
        let self = this;
        self.pages.halaman = self.pages.halaman + 1;
        self.loadData();
    },
    last(){
        let self = this;
        self.pages.halaman = self.pages.ttlhalaman;
        self.loadData();
    },
    setKlikRow(index, dt){
        let self = this;
        self.selectedRow = index;
        self.rec = Object.assign({}, dt);
    },
    loadData(){
        this.screenHeight = window.innerHeight;
        let self = this;
        self.selectedRow = -1;
        self.rec = {};
        let params = {
            pfunction:'show',
            limit: self.pages.limit,
            page: self.pages.halaman,
            search: self.pages.search
        }
        axios.post("produk/ApiProduk.php",params).then(function (response) {
            let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
            self.pages.ttlrec = parseFloat(response.data.ttlrec);
            self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
            // eslint-disable-next-line no-self-assign
            response.data.rec.map(function(val){
                val['idx'] = idx;
                idx++;
            });
            self.recs = response.data.rec;
        });
    },
    showRefresh(){
        this.pages.search = "";
        this.loadData();
    },
    loadDataSearch(filter){
        this.pages.search = filter;
        this.loadData();
    },
    async showAdd() {
            let self = this;
            self.stsAdd = true;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'PRO',
                vc_table : 'tb_produk'
            });
            self.rec = {
                tp_id : key.data,
                tk_id : '',
                tb_id : '',
                tp_name : '',
                tp_desc : '',
                tp_dim : '',
                tp_tokped : '',
                tp_wa :'6281230216381',
                ti_id : '',
                status : ''
            };
            document.getElementById("modal-form").style.display = "block";
            setTimeout(function(){
                document.getElementById('tp_name').focus();
            },500);
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('Pilih Produk Terlebih Dahulu');
                return false;
            }else{
                self.stsAdd = false;
                document.getElementById('modal-form').style.display='block';

            }
        },
        handleFileChange(event) {
      const files = event.target.files;
      const maxFileSize = 1024 * 1024; // 200 KB

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size <= maxFileSize) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const preview = e.target.result;
            this.selectedFiles.push({
              file,
              preview
            });
          };

          reader.readAsDataURL(file);
        } else {
          // File size exceeds the maximum limit
          alert('Ukuran file melebihi batas maksimum (200 KB).');
        }
      }
    },
    uploadImages() {
      this.selectedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64Image = reader.result.split(',')[1];

          // Mengirim data gambar ke server PHP menggunakan Axios
          axios
            .post('produk/ApiProduk.php', {
                pfunction : 'upimage',
                image: base64Image,
            })
            .then((response) => {
              console.log(response.data);
              // Lakukan tindakan lanjutan setelah berhasil mengunggah gambar
            })
            .catch((error) => {
              console.log(error);
              // Tangani kesalahan jika ada
            });
        };
        reader.readAsDataURL(file.file);
      });
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    addFile() {
      this.$refs.fileInput.click();
    },
    saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            axios.post("produk/ApiProduk.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.uploadImages();
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih Produk.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = {
                                tp_id : self.rec.tp_id,
                                pfunction : "delete"
                            };
                            axios.post("produk/ApiProduk.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
    setStatus(dt) {
        if (dt === "1") {
            return "<div class='btn btn-success btn-sm' ><span class='fa fa-check'></span></div>";
        } else {
            return "<div class='btn btn-danger btn-sm'><span class='fa fa-close'></span><div>";
        }
    },
    setInit() {
        let self = this;
        axios.post("produk/ApiBrand.php",{
            pfunction : 'showall'
        }).then(function (response) {
            self.brands = response.data.rec;
        });
        axios.post("produk/ApiKat.php",{
            pfunction : 'showall'
        }).then(function (response) {
            self.kategori = response.data.rec;
        });
    }
},
filters: {
    toRp(value) {
        return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
    },
    pNumber(num) {
        var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
    }
},
watch: {
    // 'moveCenter': function(){
    //     console.log("OK");
    // }
},
computed :{
    filterByShow(){
        return  this.filters.fields.filter(event => {
            return event.grid == true;
        });
    }
},
mounted(){
    this.screenHeight = window.innerHeight;
    this.setInit();
    this.loadData();
}
};
</script>